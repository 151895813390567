import React, { FC } from "react";

const languages = [
  "python",
  "javascript",
  "typescript",
  "java",
  "c",
  "cpp",
  "csharp",
  "go",
  "ruby",
  "swift",
  "kotlin",
  "php",
  "perl",
  "r",
  "scala",
  "rust",
  "dart",
  "elixir",
  "haskell",
  "lua",
  "matlab",
  "objective-c",
  "shell",
  "sql",
  "vb",
];

const LanguageSelector: FC<{ onSelect: (lang: string) => void }> = ({
  onSelect,
}) => {
  return (
    <select onChange={(e) => onSelect(e.target.value)} defaultValue="python">
      {languages.map((lang) => (
        <option key={lang} value={lang}>
          {lang}
        </option>
      ))}
    </select>
  );
};

export default LanguageSelector;
