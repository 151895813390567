import React, { useCallback, useState } from "react";
import axios from "axios";
import { Tooltip } from "react-tooltip";
import EditorWindow from "../components/EditorWindow";
import OutputWindow from "../components/OutputWindow";
import "./HomeScreen.css";

const DEFAULT_LANGUAGE = "python";

const HomeScreen = () => {
  const [output, setOutput] = useState<string>("");
  const [language, setLanguage] = useState<string>(DEFAULT_LANGUAGE);

  const handleRunCode = useCallback(
    (code: string) => {
      (async () => {
        try {
          setOutput("Running code...");
          const res = await axios.post("https://executeai.vercel.app/run", {
            code,
            language,
          });
          setOutput(res.data.output);
        } catch (error) {
          setOutput("Error running code");
        }
      })();
    },
    [language]
  );

  return (
    <div className="home-screen">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 5,
          paddingBottom: 5,
        }}
      >
        <span>
          <strong style={{ fontSize: 20 }}>EXECUTE.BOT</strong>
        </span>
        <span
          data-tooltip-id="my-tooltip"
          data-tooltip-content="Execute.bot is a REPL that runs code directly with LLMs. Project by @neuralnuance."
          style={{
            color: "lightgray",
            cursor: "pointer",
          }}
        >
          What is this?
        </span>
        <Tooltip id="my-tooltip" />
      </div>
      <div className="main">
        <EditorWindow
          onRun={handleRunCode}
          language={language}
          onSelectLanguage={setLanguage}
        />
        <OutputWindow output={output} />
      </div>
    </div>
  );
};

export default HomeScreen;
