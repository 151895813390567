import React, { FC } from "react";
import "./OutputWindow.css";

const OutputWindow: FC<{ output: string }> = ({ output }) => {
  return (
    <div className="output-window">
      <h3>Output:</h3>
      <pre>{output}</pre>
    </div>
  );
};

export default OutputWindow;
