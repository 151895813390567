import React, { useState, FC } from "react";
import Editor, { OnChange } from "@monaco-editor/react";
import LanguageSelector from "./LanguageSelector";
import "./EditorWindow.css";

const EditorWindow: FC<{
  onRun: (code: string) => void;
  language: string;
  onSelectLanguage: (lang: string) => void;
}> = ({ onRun, language, onSelectLanguage }) => {
  const [code, setCode] = useState<string>(DEFAULT_CODE);

  const handleEditorChange: OnChange = (value) => {
    setCode(value || "");
  };

  return (
    <div className="editor-window">
      <div className="controls">
        <button onClick={() => onRun(code)}>Run Code</button>
        <LanguageSelector onSelect={onSelectLanguage} />
      </div>
      <Editor
        height="90vh"
        language={language}
        value={code}
        onChange={handleEditorChange}
        theme="vs-dark"
        options={{
          automaticLayout: true,
          cursorStyle: "line",
          tabSize: 2,
          insertSpaces: true,
          smoothScrolling: true,
          minimap: { enabled: false },
          contextmenu: true,
          lineNumbers: "on",
        }}
      />
    </div>
  );
};

const DEFAULT_CODE = `def Fibonacci(n):
    if n < 0:
        print("Incorrect input")
    elif n == 0:
        return 0
    elif n == 1 or n == 2:
        return 1
    else:
        return Fibonacci(n-1) + Fibonacci(n-2)

Fibonacci(10)`;

export default EditorWindow;
